.header {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
.container {
  flex: 1;
  /* background-color: "red"; */
}
.footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
.nav-link {
  color: #825d37;
  font-weight: bold;
}
.btn.btn-primary {
  background: #825d37;
  border-color: #825d37;
  outline: none;
  border: none;
  box-shadow: none;
}
.my-icon {
  font-size: 30px;
  color: #825d37;
  padding: 3px 4px;
}
.textColor {
  color: #825d37;
}
.texthover:hover {
  color: #825d37;
}
.LinkColor {
  color: #212529;
  font-size: 14px;
}
.categoriesScroll {
  width: 100%;
  overflow: scroll;
}
.topMargin {
  margin-top: 75px;
}
li.list-group-item {
  display: flex;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.my-nav-link {
  color: #212529;
  font-weight: bold;
  text-decoration: none;
}
.logo {
  margin-top: 8%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pTag {
  margin: 0px;
  padding: 0px;
}
.noScroll {
  overflow: hidden;
}
.image-hover {
  transition: transform 0.3s ease;
}

.image-hover:hover {
  transform: scale(1.1);
}
.horizontalContainer {
  display: flex;
}

.item {
  height: auto;
  width: auto;
  padding: 4px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-width: 0.5px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: gray;
  padding-top: 0px;
}
.itemColor {
  height: auto;
  width: auto;
  padding: 4px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-width: 0.5px;
  border-bottom-width: 2px;
  border-style: solid;
  padding-top: 0px;
  border-color: #47bd67;
}
.address {
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-bottom-width: 2px;
  margin-top: 4px;
}
.addressSelect {
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-bottom-width: 2px;
  margin-top: 4px;
  border-color: #47bd67;
}
.cart {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: aliceblue;
  margin-left: -12px;
  margin-top: -4px;
}
.orderDetailsStyle {
  display: flex;
  justify-content: space-between !important;
  padding-left: 20px !important;
  padding-right: 85px !important;
}
.profileCard {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.AddAddress {
  width: 100%;
  background: #825d37;
  height: 40px;
  padding: 6px;
  color: #f1f1f1;
  font-size: larger;
  font-weight: 600;
}
/* #searchDetails {
  display: none;
} */
/* ::after, ::before {
  box-sizing: inherit;
} */
.Anchor {
  text-decoration: none;
}

.imgSlider {
  height: 300px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .imgSlider {
    height: 100px;
    max-width: 768px;
    margin-top: 10px;
  }
  .pdf {
    height: 900px;
  }
  .SubCategoriesProduct {
    width: 20%;
    padding-right: 0px;
  }
  .ProductCategories {
    width: 80%;
    padding-left: 0px;
  }
  li.list-group-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .texthover {
    text-overflow: ellipsis;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .hrTag {
    display: none;
  }
}

@media (max-width: 479px) {
  .imgSlider {
    height: 160px;
    max-width: 479px;
    margin-top: 10px;
  }
  .pdf {
    height: 500px;
  }
  .singaleContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .imageContainer {
    height: 500px;
  }
}
@media (min-width: 990px) {
  .btn_offcanvas,
  .searchInput_fild {
    display: none;
  }
  .navbar {
    flex-wrap: nowrap;
  }
}
@media (max-width: 990px) {
  .searchInput_fild1 {
    display: none;
  }
}
@media (max-width: 470px) {
  .multiImage {
    display: flex;
    overflow-x: scroll;
    margin: 2px;
  }
  .SubCategoriesProduct {
    width: 20%;
  }
  .ProductCategories {
    width: 80%;
  }
}
@media (max-width: 450px) {
  .SubCategoriesProduct {
    width: 20%;
    padding-right: 0px;
  }
  .ProductCategories {
    width: 80%;
    padding-left: 0px;
  }
}
.iconClass {
  height: 25%;
  position: absolute;
  right: 2%;
  top: 3%;
}
.whatsappButtonContainer {
  position: fixed;
  bottom: 50px;
  right: 40px;
  z-index: 1000;
  cursor: pointer;
}
.pageNotFound {
  margin: 10px;
  margin-bottom: 40px;
}
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure at least the full height of the viewport */
}

.fixed-footer {
  margin-top: auto; /* Push the footer to the bottom of the page */
  background-color: #f0f0f0; /* Set a background color for the footer */
  padding: 10px; /* Add padding as needed */
}
.categories-container {
  display: flex;
  overflow-x: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1;
  height: 50px; /* Adjust the height as needed */
  width: 100%; /* Set the width to 100% or a specific value */
}

.categories-container::-webkit-scrollbar {
  display: none;
}

.category-item {
  padding: 5px;
  white-space: nowrap;
  margin-right: 10px;
}

.category-item span {
  font-size: 14px; /* Adjust the font size as needed */
  display: inline-block; /* Ensure the text stays on one line */
}
.slider-button {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}
.slider-button {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1000;
}
.swiper-button-next,
.swiper-button-prev {
  position: relative;
  top: 105px;
  bottom: 0;
  z-index: 1;
  color: #fff;
  background-color: #825d37;
  border: 0;
  padding: 10px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.swiper-button-next1,
.swiper-button-prev1 {
  position: relative;
  /* top: 105px; */
  bottom: 0;
  /* z-index: 1; */
  color: #fff;
  background-color: #825d37;
  border: 0;
  padding: 10px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.swiper-slide.category-item {
  width: auto !important;
  padding: 0rem;
  height: 2rem;
  padding-top: 0.5rem;
  /* border: 1px solid black; */
}
